.book-cover {
    transition: transform 0.3s ease-in-out;
    transform-origin: left center; /* Ensures the book opens from the left */
    border-radius: 10px !important; /* Rounded corners for the book cover */
  }
  
  .book-cover:hover {
    transform: perspective(1000px) rotateY(-30deg); /* 3D effect on hover */
  }
  
  .no-margin-bottom {
    margin-bottom: 0 !important; /* Removes the bottom margin if needed */
  }
  
  .book-cover-img {
    filter: brightness(1.2) contrast(1.2); /* Enhance brightness and contrast, adjust values as needed */
    clip-path: inset(2px 0 0 0); /* Hide the thin blue line at the top, adjust the '2px' as necessary */
    margin-top: -2px;
    border-radius: 10px; /* If you want rounded corners for the image itself */
  }
  